import React, { useState } from 'react'
import './App.css'
import { ProgressBar } from 'react-loader-spinner'
import { Button, Container, Form, Navbar } from 'react-bootstrap' 
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'

function App() {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState(null);

  const validations = () => {
    if (prompt.length === 0) {
      toast('Enter a prompt to generate images...')
      return false
    }
    if (prompt.length > 80) {
      toast('Prompt length is too long, Try again with a shorter length...')
      return false
    }

    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!validations()) {
      return false
    }
    setLoading(true)
    axios.get(`https://api-colors.gen-ai.varanbhalla.com/generateimages/${prompt}`)
      .then(response => {
        setLoading(false);
        setImages(response.data)
        toast('Image Generated Successfully...')
        setLoading(false)
      })
      .catch(error => {
        if (error.response && error.response.status === 429) {
          toast('Too many requests. Kindly try again later...');
        } else if (error.response && error.response.status === 500) {
          toast('Internal server error. Kindly try again later');
        } else {
          toast('An error occurred. Kindly try again later.');
        }
        setLoading(false)
      });
  };

  const downloadImage = () => {
    if (images) {
      window.open(images, '_blank')
    }
  }

  return (
    <center>
      <h2 className='m-4'>IMAGE GENERATOR DALL-E</h2>
      <Navbar bg="body-tertiary" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="my-4">
        <Form>
          <Form.Group className="mb-3">
            <h3>Enter a Prompt to Generate Great Images</h3>
            <Form.Control style={{ width: '70%', margin: 30 }} type="text" placeholder="Example: Person sitting on moon" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
            <Form.Text>Generative AI Image Generator - Using DALL-E</Form.Text>
          </Form.Group>
          <Button className='m-2' variant="success" onClick={handleSubmit}>Generate Image</Button>
          {
            images && <Button variant="danger" onClick={downloadImage}>Download Image</Button>
          }
        </Form>

        <div>
          <div>
          {
            loading && <ProgressBar
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="progress-bar-loading"
            />
          }
          </div>
         {
          images &&  
            <center className="">
                <img src={images} alt={`Image`} className="img-responsive" />
          </center>
         }
        </div>
      <Toaster />
      </Container>
    </center>
  );
}

export default App;
